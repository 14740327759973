define("discourse/plugins/discourse-pm-auto-responder-for-admins/discourse/components/auto-pm-toggler", ["exports", "@ember/component", "@glimmer/component", "@ember/object", "@ember/service", "discourse/lib/ajax", "discourse-common/utils/decorators", "@ember/template-factory"], function (_exports, _component, _component2, _object, _service, _ajax, _decorators, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _descriptor;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <DButton
    @action={{action "toggleAutoPm"}}
    @icon={{this.toggleButtonIcon}}
    @class="auto-pm-toggler btn-flat"
    @title={{this.getTitle}}
  />
  */
  {
    "id": "CFJYb9kh",
    "block": "[[[8,[39,0],null,[[\"@action\",\"@icon\",\"@class\",\"@title\"],[[28,[37,1],[[30,0],\"toggleAutoPm\"],null],[30,0,[\"toggleButtonIcon\"]],\"auto-pm-toggler btn-flat\",[30,0,[\"getTitle\"]]]],null]],[],false,[\"d-button\",\"action\"]]",
    "moduleName": "discourse/plugins/discourse-pm-auto-responder-for-admins/discourse/components/auto-pm-toggler.hbs",
    "isStrictMode": false
  });
  let AutoPmToggler = _exports.default = (_dec = (0, _object.computed)("storedOverride"), (_class = class AutoPmToggler extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "currentUser", _descriptor, this);
    }
    get toggleButtonIcon() {
      return "power-off";
    }
    get getTitle() {
      const propertyPath = "custom_fields.mmn_auto_respond_pm";
      return this.currentUser.get(propertyPath) ? "mmn_auto_respond_pm.disable" : "mmn_auto_respond_pm.enable";
    }
    setClassAndLabel(enabled) {
      const className = "pm-auto-responder-on";
      const method = enabled ? "add" : "remove";
      $("html")[`${method}Class`](className);
    }
    toggleAutoPm() {
      const propertyPath = "custom_fields.mmn_auto_respond_pm";
      const path = this.currentUser.get(propertyPath) ? "disable" : "enable";
      this.currentUser.toggleProperty(propertyPath);
      var loading = true;
      var _this = this;
      (0, _ajax.ajax)(`/mmn_auto_respond_pm/${path}`).catch(e => console.error(e)).then(result => {
        if (result.status != "ok") {
          _this.currentUser.toggleProperty(propertyPath);
        }
      }).finally(() => {
        _this.setClassAndLabel(_this.currentUser.get(propertyPath));
        loading = false;
      });
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "currentUser", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "toggleButtonIcon", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "toggleButtonIcon"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "toggleAutoPm", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "toggleAutoPm"), _class.prototype)), _class));
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, AutoPmToggler);
});